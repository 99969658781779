import React from 'react';
import {PayPalButtons} from "@paypal/react-paypal-js";
import {useNavigate } from 'react-router-dom';
import {  useRef } from "react";
import { useMutation } from '@apollo/client';
import { ADD_ORDER, UPDATE_ORDER, UPDATE_STATUS, UPDATE_TIME_FALSE, PULL_HOUR, UPDATE_TIME_TRUE} from "../utils/mutations";
import { jsPDF } from "jspdf"
import "../style.css"


const ButtonWrapper = ({
  currency,
  objDate,
  howdelivery,
  order,
  TotalOrder,
  orderDescription,
  selectedDate,
  deliveryInstructions, 
  selectedTime,
  myemail, 
  myaddress,
  mypostalcode,
  myname,
  myphone,
  setIsFilled,
  selectedCity,
   deliveryFee,
   subTotal,
  //  taxes,
 }) => {
      
   
    const timeoutRef = useRef()
    const rangeRef = useRef(objDate)
    const isTimePicked = useRef(false)
    const paypalRef = useRef(' ')
    const orderInvoice = useRef(' ')
    const issueDate = useRef(' ')

    const navigate = useNavigate();
    const Totalh = useRef(TotalOrder)
    const [addOrder] = useMutation(ADD_ORDER);
    const [updateOrder] = useMutation(UPDATE_ORDER);
    const [updateOrderStatus] = useMutation(UPDATE_STATUS);
    const [updateTimeFalse] = useMutation(UPDATE_TIME_FALSE);
    const [updateTimeTrue] = useMutation(UPDATE_TIME_TRUE);
    const [pullHour] = useMutation(PULL_HOUR);
    const istimeout=useRef(false)
    const myorderID = useRef('');
     
    const handleCancel= async () => {
      return window.location.replace('/');
      }
    
    const handleTimeOut = async () => {
      Totalh.current="0"
        istimeout.current=true

      if(howdelivery==="Delivery"){
        try {
         await updateTimeFalse({
          variables: {
          date: selectedDate,
          hour: selectedTime
          }
          })
      
          }catch(err){
          alert('time not updated')
          }
        }
      }

      const generatePDF = async() => {

       if (howdelivery==='Pick up') {
        const doc1 = new jsPDF({
          orientation: 'portrait',
          unit: 'mm',
          format: 'a4',
          margin: {
            top: 15,
            bottom: 15,
            left: 15,
            right: 15
          }
        });
        
        doc1.setTextColor(0, 0, 0);;
        doc1.text(`INVOICE#: ${orderInvoice.current}`, 15, 15);
      
        let offsetY = 25;
      
        doc1.setFontSize(11);
        doc1.text(`Issued on: ${issueDate.current}`, 15, offsetY);
        offsetY += 5;
        doc1.text(`Seller: Bitelandia`, 15, offsetY);
        offsetY += 5;
        doc1.text(`BIN #: 1000736101`, 15, offsetY);
        offsetY += 5;
        doc1.text(`Seller Address: 1130 Queens Ave, Oakville-On`, 15, offsetY);
        offsetY += 5;
        doc1.text(`Seller Contact: sales@bitelandia.com`, 15, offsetY);
        offsetY += 7;
        doc1.text(`Sold to: ${myname}`, 15, offsetY);
        offsetY += 5;
        doc1.text(`Delivery: to be picked up by customer`, 15, offsetY);
        offsetY += 7;

        doc1.text(`Food ordered:`,20, offsetY);
        offsetY += 4;
        doc1.text(`----------------------------------------------------------------------------------------------------------------------------------------`, 15, offsetY);
        offsetY += 4;
      //within section enclosed in visible border
      for (const cakeOrder of order) {
        let orderText
        const toppings = cakeOrder.addToppings.length ? cakeOrder.addToppings.join(', ') : 'none';
        const frosty = cakeOrder.addFrosty? cakeOrder.addFrosty: 'none';
        
        if(frosty==="N/A"){
          if(cakeOrder.item==="Almonds cookie"||cakeOrder.item==="Chocolate cookie"||cakeOrder.item==="Mocha cookie"){
            orderText = `**${cakeOrder.item}, Quantity: ${cakeOrder.quantity} x 6(${cakeOrder.quantity*150} grams), Total: $${cakeOrder.total}**`; 
          }else if(cakeOrder.item==="Ginger cookie"){
            orderText = `**${cakeOrder.item}, Quantity: ${cakeOrder.quantity} x 12(${cakeOrder.quantity*108} grams), Total: $${cakeOrder.total}**`
          }else{
            orderText = `**${cakeOrder.item}, Quantity: ${cakeOrder.quantity}( x 650 grams/unit), Total: $${cakeOrder.total}**`; 
          }

        }else{
          orderText = `**${cakeOrder.item}, Quantity: ${cakeOrder.quantity}, Toppings: ${toppings}, Frosting: ${frosty}, Sweetener: ${cakeOrder.sweetener}, Total: $${cakeOrder.total}**`; 
        }
       
        const foodToLines = doc1.splitTextToSize(orderText, 170);
        doc1.text(foodToLines, 20, offsetY);
        offsetY += 12; // increase by a larger value than 7
      }
        offsetY += 2;
        doc1.text(`---------------------------------------------------------------------------------------------------------------------------------------`, 15, offsetY);
        offsetY += 7;

        doc1.text(`food: $${subTotal}`, 20, offsetY);
        offsetY += 3;
       
        doc1.text(`_____________________`, 20, offsetY);
        offsetY += 4;
        doc1.text(`--Order Total: $${TotalOrder}--`, 20, offsetY);
        offsetY += 7;
        doc1.text(`Available to be picked up starting on: ${selectedDate}`, 15, offsetY);
        offsetY += 5;
        doc1.text(`Pick up time: ${selectedTime}`, 15, offsetY);
        offsetY += 5;
        const inst = `${deliveryInstructions}`
        const foodToLines2 = doc1.splitTextToSize(inst, 170);
        doc1.text(foodToLines2, 15, offsetY);
        offsetY += 12;
        doc1.text(`Paypal ref#: ${paypalRef.current}`, 15, offsetY);
      
        doc1.save(`BitelandiaInvoice#: ${orderInvoice.current}.pdf`);
       } else {
        const doc = new jsPDF({
          orientation: 'portrait',
          unit: 'mm',
          format: 'a4',
          margin: {
            top: 15,
            bottom: 15,
            left: 15,
            right: 15
          }
        });
        rangeRef.current = objDate.replace(/\s|\/+/g, '');
        const dateP = rangeRef.current
        const [startTime, endTime] = dateP.split("to");
        doc.setFontSize(14);
        doc.text(`INVOICE#: ${orderInvoice.current}`, 15, 15);
      
        let offsetY = 25;
        
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(11);
        doc.setFontSize(11);
        doc.text(`Issued on: ${issueDate.current}`, 15, offsetY);
        offsetY += 5;
        doc.text(`Seller: Bitelandia`, 15, offsetY);
        offsetY += 5;
        doc.text(`BIN #: 1000736101`, 15, offsetY);
        offsetY += 5;
        doc.text(`Seller Address: 1130 Queens Ave, Oakville-On`, 15, offsetY);
        offsetY += 5;
        doc.text(`Seller Contact: sales@bitelandia.com`, 15, offsetY);
        offsetY += 7;
        doc.text(`Sold to: ${myname}`, 15, offsetY);
        offsetY += 5;
        doc.text(`Delivery address: ${myaddress}, ${selectedCity}, ${mypostalcode}`, 15, offsetY);
        offsetY += 7;

        doc.text(`Food ordered:`,20, offsetY);
        offsetY += 4;
        doc.text(`----------------------------------------------------------------------------------------------------------------------------------------`, 15, offsetY);
        offsetY += 4;
      //within section enclosed in visible border
      for (const cakeOrder of order) {
        const toppings = cakeOrder.addToppings.length ? cakeOrder.addToppings.join(', ') : 'none';
        const frosty = cakeOrder.addFrosty? cakeOrder.addFrosty: 'none';
        let orderText
        if(frosty==="N/A"){
          if(cakeOrder.item==="Almonds cookie"||cakeOrder.item==="Chocolate cookie"||cakeOrder.item==="Mocha cookie"){
            orderText = `**${cakeOrder.item}, Quantity: ${cakeOrder.quantity} x 6(${cakeOrder.quantity*150} grams), Total: $${cakeOrder.total}**`; 
          }else if(cakeOrder.item==="Ginger cookie"){
            orderText = `**${cakeOrder.item}, Quantity: ${cakeOrder.quantity} x 12(${cakeOrder.quantity*108} grams), Total: $${cakeOrder.total}**`
          }else{
            orderText = `**${cakeOrder.item}, Quantity: ${cakeOrder.quantity} ( x 650 grams/unit), Total: $${cakeOrder.total}**`; 
          }

        }else{
          orderText = `**${cakeOrder.item}, Quantity: ${cakeOrder.quantity}, Toppings: ${toppings}, Frosting: ${frosty}, Sweetener: ${cakeOrder.sweetener}, Total: $${cakeOrder.total}**`; 
        }
     
        const foodToLines = doc.splitTextToSize(orderText, 170);
        doc.text(foodToLines, 20, offsetY);
        offsetY += 12; // increase by a larger value than 7
      }
        offsetY += 2;
        doc.text(`---------------------------------------------------------------------------------------------------------------------------------------`, 15, offsetY);
        offsetY += 7;
        doc.text(`food: $${subTotal}`, 20, offsetY);
        offsetY += 5;
        doc.text(`delivery fee: $${deliveryFee}`, 20, offsetY);
        offsetY += 3;
        doc.text(`_____________________`, 20, offsetY);
        offsetY += 4;
        doc.text(`--Order Total: $${TotalOrder}--`, 20, offsetY);
        offsetY += 7;
        doc.setFont('normal');
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(11);
        doc.text(`To be delivered on(date): ${selectedDate}`, 15, offsetY);
        offsetY += 5;
        doc.text(`To be delivered at (time):${startTime} to ${endTime}`, 15, offsetY);
        offsetY += 5;
        const inst2 = `${deliveryInstructions}`
        const foodToLines3 = doc.splitTextToSize(inst2, 170);
        doc.text(`Customer instructions:${foodToLines3}`, 15, offsetY);

        offsetY += 7;
        doc.text(`Paypal ref#: ${paypalRef.current}`, 15, offsetY);
      
        doc.save(`BitelandiaInvoice#: ${orderInvoice.current}.pdf`);
        
       }
         
      };
  
    const handleInitiation= async (b) => { 

     if (howdelivery==='Pick up') {
      try {
        const { data } = await addOrder({
          variables: {
          name: myname,
          address: `${myaddress}, ${selectedCity}, ${mypostalcode}`,
          total: Totalh.current,
          deliveryDate: selectedDate,
          status: "pending payment",
          deliveryTime: selectedTime,
          paypalRef:b,
          deliveryIns: deliveryInstructions,
          phone: myphone,
          email:myemail,
          postalCode:mypostalcode,
          delivery: "Pick up"
          },
        });
    
        const orderI = data.addOrder._id;
        const dateIss = data.addOrder.createdAt;
        
        myorderID.current = orderI
        issueDate.current = dateIss 
        
        for (let i = 0; i < order.length; i++) {
          var Cake = order[i].item
          var Quantity = order[i].quantity
          var Total = order[i].total     
          var Frosty = order[i].addFrosty     
          var Toppings = order[i].addToppings     
          var Sweetener = order[i].sweetener     
          try {
            await updateOrder({
              variables: {
              orderId: orderI,
              quantity: `${Quantity}`,
              cake: `${Cake}`,
              total: `${Total}`,
              frosty:`${Frosty}`,
              toppings:`${Toppings}`,
              sweetener:`${Sweetener}`
              },
            })
          
          } catch (err) {
            Totalh.current="0"
            window.alert(`ERR01:Something went wrong with order processing, We apologize for any inconvenience`);
          handleCancel()
          return
          }
        }
      } catch (err) {
        Totalh.current="0"
        window.alert(`ERR02:Something went wrong with order processing. We apologize for any inconvenience`);
      handleCancel()
      return
      }
     } else {
      try {
      await updateTimeTrue({
            variables: {
              date: selectedDate,
              hour: selectedTime
            }
          });

              
        
        } catch (err) {     
              
              window.alert('the time selected has been picked by other user, try again')
              setIsFilled(false)
              isTimePicked.current=true
              navigate('/Order')
         
          return
          }
        
    try {
      const { data } = await addOrder({
        variables: {
        name: myname,
        address: `${myaddress}, ${selectedCity}, ${mypostalcode}`,
        total: Totalh.current,
        deliveryDate: selectedDate,
        status: "pending payment",
        deliveryTime: selectedTime,
        paypalRef:b,
        deliveryIns: deliveryInstructions,
        phone: myphone,
        email:myemail,
        postalCode:mypostalcode,
        delivery: "Delivery"
        },
      });
  
      const orderI = data.addOrder._id;
      const dateIss = data.addOrder.createdAt;
      
      myorderID.current = orderI
      issueDate.current = dateIss   

      for (let i = 0; i < order.length; i++) {
        var Cake2 = order[i].item
        var Quantity2 = order[i].quantity
        var Total2 = order[i].total     
        var Frosty2 = order[i].addFrosty     
        var Toppings2 = order[i].addToppings     
        var Sweetener2 = order[i].sweetener     
        try {
          await updateOrder({
            variables: {
            orderId: orderI,
            quantity: `${Quantity2}`,
            cake: `${Cake2}`,
            total: `${Total2}`,
            frosty:`${Frosty2}`,
            toppings:`${Toppings2}`,
            sweetener:`${Sweetener2}`
            },
          });

          
          
        } catch (err) {
          Totalh.current="0"
          window.alert(`ERR01:Something went wrong with order processing, We apologize for any inconvenience`);
        handleCancel()
        return
        }
      }
    } catch (err) {
      Totalh.current="0"
      window.alert(`ERR02:Something went wrong with order processing. We apologize for any inconvenience`);
      
    handleCancel()
    return
    }
     }

    };

    const handleCapture = async (a) => {
        if(a){
          if (howdelivery==='Pick up') {
            try {
              const { data: data1 } = await updateOrderStatus({
                variables: {
                  orderId: myorderID.current,
                  newstatus: "completed",
                  payerId: a
                },
              });
  
              // if (data1 && data1.updateOrderStatus) {
                orderInvoice.current=data1.updateOrderStatus.invoice

                generatePDF()
                navigate(`/${data1.updateOrderStatus._id}`)

            } catch (err) {
              window.alert(`ERROR04:Something went wrong with order updating, please check your paypal/debit/credit card and claim a refund if a money transfer was made.We apologize for any inconvenience`);
              navigate('/UpdateError')
            }
          } else {
            try {
              await pullHour({
                variables: {
                  date: selectedDate,
                  hour: selectedTime,
                },
              });
  
                        
          
              try {
                const { data: data1 } = await updateOrderStatus({
                  variables: {
                    orderId: myorderID.current,
                    newstatus: "completed",
                    payerId: a
                  },
                });
                
                // if (data1 && data1.updateOrderStatus) {
                  orderInvoice.current=data1.updateOrderStatus.invoice
  
                  generatePDF()
                  navigate(`/${data1.updateOrderStatus._id}`)
  
              } catch (err) {
                window.alert(`ERROR04:Something went wrong with order updating, please check your paypal/debit/credit card and claim a refund if a money transfer was made.We apologize for any inconvenience`);
            
                navigate('/UpdateError')
              }
            
            } catch (err) {
              window.alert(`ERROR05:Something went wrong with order updating,please check your paypal/debit/credit card and claim a refund if a money transfer was made.We apologize for any inconvenience`);
            
              navigate('/UpdateError')
              
            }
          }
   
        }else{
          window.alert(`ERROR06:Something went wrong, payment was not received, please check your paypal/debit/credit card and claim a refund if a money transfer was made.We apologize for any inconvenience`);
          navigate('/UpdateError')
        }

    };
    
    if(istimeout.current===true){
      return(
        <div className='grid-container'>
   <button className=" proceedBtn" style={{ cursor: 'pointer' }} onClick={handleCancel}>Cancel</button>
        <h1>sesion timeout, you can Cancel and try again</h1>
    
        </div>      
      )
      
    }else{
      return (

          <div className='grid-container'>
            
          <button className=" proceedBtn" style={{ cursor: 'pointer' }} onClick={handleCancel}>Cancel Order</button>
                    
        <div className='paypalbtns'>
        <PayPalButtons
            forceReRender={[Totalh, currency, istimeout,isTimePicked]}
            createOrder={(data, actions) => {
              return actions.order
                .create({
                  purchase_units: [
                    {
                      amount: {
                        currency_code: currency,
                        value: Totalh.current,
                      },
                      description: orderDescription,
                    },
                  ],
                  application_context: {
                    shipping_preference: "NO_SHIPPING",
                  },
                })
                .then((orderId) => {
                
            handleInitiation(orderId)
            
            setTimeout(() => {
              handleTimeOut()
            },12*60*1000)
            if(isTimePicked.current===true){
              clearTimeout(timeoutRef.current);
            }
                  paypalRef.current=orderId
                  return orderId;
              });
            }}
            onApprove={(data, actions) => {
              
              if (Totalh.current> 0) {
                return actions.order.capture().then(() => {
                 
                  clearTimeout(timeoutRef.current);
                  const validate=data.payerID
                  handleCapture(validate);
               
                });
              } else {
                window.alert(`ERROR07:Something went wrong, payment failed.`);
                navigate('/error07')

              }
            }}
            
          />
        </div>
          <h3>An invoice will be created automatically once payment is approved</h3>
          <h3>Food Ordered:</h3>
          {order.map((element) => (
     
          <h3 key={element.item}>**{element.item}| qty:{element.quantity}{element.addFrosty==="N/A"||element.addFrosty===''?'':'|Frosty: '+element.addFrosty}{element.addToppings.length===0?'':'|Toppings: '+(element.addToppings).join(', ')}{element.sweetener==="N/A"?'':'|Sweetener: '+element.sweetener}**</h3>        
        
          ))
          }
     
          {/* <h3>Total Order: ${TotalOrder}(food ordered:${subTotal} + delivery fee:${deliveryFee} + taxes(13% HST): ${taxes})</h3> */}
          <h3>Total Order: ${TotalOrder}(food ordered:${subTotal} + delivery fee:${deliveryFee})</h3>
         
          
          {howdelivery==='Pick up'? (
            <div>
               <div style={{ display: "flex", justifyContent: "center" }}>
              <h3>Pick up date/time:{selectedDate}, {selectedTime}</h3>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
           <h3>{deliveryInstructions}</h3>
           </div>
           </div>
          ) : (
          <div> 
             <h3>Delivery address: {myaddress}, {selectedCity}, {mypostalcode}</h3>
            <h3>delivery date/time:{selectedDate}, {objDate}</h3>
            <h3>delivery instructions:{deliveryInstructions}</h3>
          
          </div>
          )}

   
          </div>
      );
    }
   
}

export default ButtonWrapper;
