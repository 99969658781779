import React from 'react';

import jsonData from '../../data.json';

const Cookiemocha = ({
  isOrder,
  cookieMoQty,
  setCookieMoQty,
  setCookieMoTotal,
  cookieMoTotal,
  cookieChocTotal,
  chocolateTotal,
  carrotTotal,
  vanTotal,
  bananaTotal,
  pineTotal,
  setTotal,
  tiramisuTotal,
  cookieAlmTotal

}) => 
{



function handlecookieMoIncrement() {
  setCookieMoQty(cookieMoQty + 1);
  cookieMoTotalcalc(cookieMoQty + 1)
}

function handlecookieMoDecrement() {
  if (cookieMoQty=== 1) {
    setCookieMoQty(0);
   cookieMoTotalcalc(0)
  }else if(cookieMoQty>1){
    setCookieMoQty(cookieMoQty-1);
    cookieMoTotalcalc(cookieMoQty-1)
  }
}

function cookieMoTotalcalc(b) {

  if(b===1){
    const moCookie = (jsonData.cookiemocha.set6).toFixed(2)
    setCookieMoTotal(moCookie)
    setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(moCookie*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(tiramisuTotal*1)).toFixed(2))
  }else if(b>1){
    const moCookie= (((jsonData.cookiemocha.set6am)*b)+jsonData.cookiemocha.set6ab).toFixed(2)
    setCookieMoTotal(moCookie)
    setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(moCookie*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(tiramisuTotal*1)).toFixed(2))
  }else{
    const moCookie =0
    setCookieMoTotal(0)
    setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(moCookie*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(tiramisuTotal*1)).toFixed(2))
  }
}


return (
<div>
  <div className='photoOrder'>
    <div className='cakesNameTotal'>
      <h3 className='cakeTitle'>Mocha:</h3>
      <h3>${cookieMoTotal}</h3>
    </div>
      {/* <img className='photosOrder'src={image7} alt="banana cake"></img> */}

  </div>

    {isOrder===true?(
    <div>
      <h1>Quantity:{cookieMoQty}</h1>

    </div>):
    (
    <div className='btdivflex'>
      <div  className='btdiv'>
      <button className="btplusminus" style={{ cursor: 'pointer'}}  onClick={handlecookieMoDecrement}>-</button>
      <h4 className="qty">{cookieMoQty}</h4>
      <button className="btplusminus" style={{ cursor: 'pointer'}}  onClick={handlecookieMoIncrement}>+</button>
      </div>
      <div className='btdiv6'>
      <h4>x 6(150 grams)</h4>
      </div>
    </div>
    )}
</div>
);
};

export default Cookiemocha;