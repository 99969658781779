import { gql } from '@apollo/client';


export const QUERY_DATE= gql`
query Date($datetofind: String!) {
  Date(datetofind: $datetofind) {
    _id
    date
    dateMill
    timeList {
      time
      isPicked
      timePicked
      timePickedMill
      timeMill
      timeInt
    }
  }
}
`;
export const QUERY_TAMAL_DATES= gql`
query TamalDates {
  tamalDates {
    _id
    date
    dateMill
    timeList {
      time
      isPicked
      timePicked
      timePickedMill
      timeMill
      timeInt
    }
  }
}
`;
export const QUERY_TAMAL_PDATES= gql`
query TamalPickDates {
  tamalPickDates {
    _id
    date
    dateMill
    datepIMill
    datepFMill
    formathour
  }
}
`;

export const GET_PAYPAL_CCDI = gql`
query GetPaypal {
  getPaypal {
    paypalCCID
  }
}
`;



// {
//   "datetofind": "Tue Mar 21 2023 15:00:00 GMT-0400 (Eastern Daylight Saving Time)"
// }
export const QUERY_PICKUP_DATE= gql`
query PickDate($datetofind: String!) {
  PickDate(datetofind: $datetofind) {
    _id
    date
    dateMill
  }
}
`;

// {
//   "datetofind": "Mon Apr 10 2023 15:00:00 GMT-0400 (Eastern Daylight Saving Time)"
// }


export const QUERY_DATES= gql`
query Dates {
  Dates {
    _id
    date
    dateMill
    timeList {
      time
      isPicked
      timePicked
      timePickedMill
      timeMill
      timeInt
    }
  }
}
`;
export const QUERY_PICKUP_DATES= gql`
query PickDates {
  PickDates {
    _id
    date
    dateMill
    datepIMill
    datepFMill
    formathour
  }
}
`;

export const QUERY_ORDER= gql`
query Order($orderId: ID!) {
  Order(id: $orderId) {
    _id
    invoice
    paypalRef
    name
    email
    phone
    address
    postalCode
    orderedCakes {
      quantity
      cake
      total
    }
    total
    deliveryDate
    deliveryTime
    status
    createdAt
    deliveryIns
  }
}
`;

// /{
//   "orderId":"6419c502046a84e3741963ad" 
// }

export const QUERY_ORDERS= gql`
query Orders {
  Orders {
    _id
    invoice
    paypalRef
    name
    email
    phone
    address
    postalCode
    orderedCakes {
      quantity
      cake
      total
    }
    total
    deliveryDate
    deliveryTime
    status
    createdAt
    deliveryIns
  }
}
`;

export const QUERY_THIS_TIME= gql`
query GetCurrentTime {
  getCurrentTime {
    currentTorontoTime
    currentUtcTime
  }
}
`;

export const QUERY_TIME_NOW= gql`
query GetCurrentTimeMill {
  getCurrentTimeMill {
    currentTimeMill
  }
}
`;

export const P_DATES= gql`
query GetPdatesCount {
  getPdatesCount {
    PuPDates
  }
}
`;
export const P_DATES_INFO= gql`
query GetPdatesInfo {
  getPdatesInfo {
    pDatesInfo
  }
}
`;
export const D_DATES= gql`
query GetDdatesCount {
  getDdatesCount {
    DeliveryDates
  }
}
`;
export const D_DATES_INFO= gql`
query GetDdatesInfo {
  getDdatesInfo {
    dDatesInfo
  }
}
`;

export const QUERY_PHOTOS= gql`
query GetPhotoCollection {
  getPhotoCollection {
    _id
    name
    photos {
      photourl
      phototext
    }
  }
}
`;

export const COOKIES_PHOTOS= gql`
query Imagecookies {
  Imagecookies {
    _id
    image
    imagetext
  }
}
`;

// {"image":"https://storage.googleapis.com/bitelandiaphotos/photcookies/cookie1.png",
// "imagetext":"Try our new Mocha cookies"}
