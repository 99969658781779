
import React from 'react';
import { useState, useEffect} from 'react';

const About = () => 
{
  const [text, setText] = useState(false)
  const [classmore, setTextmore] = useState("read-more-less");

  useEffect(() => {
    if(text === false) {
      setTextmore("read-more-less");
    } else {
      setTextmore("nodisplay-more");
    }
  }, [text]);
    return (

        <div>
    
      <p style={{ textAlign: 'justify', marginRight:"5px", fontWeight: "normal" }}>
      Bitelandia began with a deep love for baking and a commitment to wholesome ingredients. We take joy in transforming raw elements like almond flour, coconut sugar, and coconut oil into irresistible, spongy cakes.
        <span>
          <button className={classmore} onClick={() => setText(!text)}>..read more</button>
        </span><br></br>
       
      </p>
      {text && (
        <p style={{ textAlign: 'justify', marginRight:"5px", fontWeight: "normal" }}>
          We offer customers the chance to personalize their cakes with additional toppings such as coconut caramel and fruit jams. These are prepared in our kitchen, free of preservatives, adding a delightful touch to each cake. <br></br>
          While our specialty lies in baking cakes, we're excited about expanding our menu with other nutritious and delicious preparations.Our vision extends beyond the confines of our home kitchen. We aspire to grow Bitelandia into a larger business, delivering nutritious food to a wider audience.<br></br>
          Stay tuned for new additions to our menu as we continue to innovate, expand, and evolve.
          <span>
          <button className="read-more-less" onClick={() => setText(false)}>..read less</button>
          </span><br></br>
        </p>
      )}
        </div>
    )
}

export default About;

