import React from 'react';
import image6 from '../../components/img/CHOCOLATE.png'
import jsonData from '../../data.json';
import toppingsOptions from '../../utils/ToppingsData'
import FrostyOptions from '../../utils/FrostyData'
import {chSweetOptions} from '../../utils/SweetenerData'
import { useState} from 'react';
import { useEffect} from 'react'



const Chocolate = ({
isOrder,
chFrostyOption,
setChFrostyOption,
chToppings,
setChToppings,
chocolateTotal,
setChocolateTotal,
chocolateSize,
setChocolateSize,
chocolatequantity,
setChocolateQuantity,
carrotTotal,
bananaTotal,
setTotal,
chSweetener,
setChSweetener,
pineTotal,
vanTotal,
cookieChocTotal,
cookieMoTotal,
tiramisuTotal,
cookieAlmTotal,
cookieGiTotal

  }) => 
{
  const [chocToppmpbutton, setToppchocmpbutton] = useState(false);
const [chocHVtoppings, setChocHVtoppings] = useState("hiddenoptions");
const [chocFrosmpbutton, setFroschocmpbutton] = useState(false);
const [chocHVfrosting, setChocHVfrosting] = useState("hiddenoptions");


useEffect(() => {
if (chocToppmpbutton===true) {
setChocHVtoppings("visibleoptions")
}else{
setChocHVtoppings("hiddenoptions")
}


}, [chocToppmpbutton, setChocHVtoppings]);

const handleChocToppVis = () => {

if (chocToppmpbutton===false) {
setToppchocmpbutton(true)

}else{
setToppchocmpbutton(false)
}

}


useEffect(() => {
if (chocFrosmpbutton===true) {
setChocHVfrosting("visibleoptions")
}else{
setChocHVfrosting("hiddenoptions")
}


}, [chocFrosmpbutton, setChocHVfrosting]);

const handleChocFrosVis = () => {

if (chocFrosmpbutton===false) {
setFroschocmpbutton(true)

}else{
setFroschocmpbutton(false)
}

}
  const handleChFrOpt = (event) => {
    const value = event.target.value;
    if (chFrostyOption === value) {
      setChFrostyOption("");
      chocolateTotalcalc(chocolatequantity,chocolateSize, '', chToppings, chSweetener)
    } else {
      setChFrostyOption(value);
      chocolateTotalcalc(chocolatequantity,chocolateSize, value, chToppings, chSweetener)
    }
  };
  const handleChSwOpt = (event) => {
    const value = event.target.value;
  
    if (chSweetener === value) {

      if(value === "Coconut Sugar"){
        setChSweetener("Monk Fruit");
     
      chocolateTotalcalc(chocolatequantity,chocolateSize, chFrostyOption, chToppings, "Monk Fruit")
      
      }else if(value === "Monk Fruit"){
        setChSweetener("Coconut Sugar");
      

        chocolateTotalcalc(chocolatequantity,chocolateSize, chFrostyOption, chToppings, "Coconut Sugar")
       
      }
      

    }else{
      setChSweetener(value);
      // console.log("handle if**v=value",chSweetener)
      chocolateTotalcalc(chocolatequantity,chocolateSize, chFrostyOption, chToppings, value)
    }
  };

  const handleChToppings = (event) => {
    const optionValue = event.target.value;

    if (chToppings.includes(optionValue)) {
      setChToppings(chToppings.filter((option) => option !== optionValue));
      chocolateTotalcalc(chocolatequantity,chocolateSize, chFrostyOption, chToppings.filter((option) => option !== optionValue), chSweetener )
    } else if (chToppings.length < 2) {
      setChToppings([...chToppings, optionValue]);
      chocolateTotalcalc(chocolatequantity,chocolateSize, chFrostyOption, [...chToppings, optionValue], chSweetener)
    }
  };

  function handleChocolateChange(event) {
    setChocolateSize(event.target.value);
    chocolateTotalcalc(chocolatequantity, event.target.value, chFrostyOption, chToppings, chSweetener)
  }

  function handleChocolateIncrement() {
    setChocolateQuantity(chocolatequantity + 1);
    chocolateTotalcalc((chocolatequantity + 1),chocolateSize, chFrostyOption, chToppings, chSweetener)
  }

  function handleChocolateDecrement() {
    if (chocolatequantity=== 1) {
      setChocolateQuantity(0);
      chocolateTotalcalc(0,chocolateSize, chFrostyOption, chToppings, chSweetener)
    }else if(chocolatequantity>1){
      setChocolateQuantity(chocolatequantity-1);
      chocolateTotalcalc((chocolatequantity-1),chocolateSize, chFrostyOption, chToppings, chSweetener)
    }
  }

  function chocolateTotalcalc(b,c,d,e,f) {
    const chFrosty = d
    var addChFrosty = 0
    const chocToppings = e
    var addChToppings = 0
    const chSwtner = f
    var addChSwtner = 0

    

    if(c==="1000 grams"){
      if(chFrosty){

        var optionset11=FrostyOptions.find((Frosty) => Frosty.value === chFrosty)
        if(optionset11){
          var price11= optionset11.price.find((price)=> price.cake === "1000 grams")
          if(price11){
            addChFrosty=price11.priceTag
          }
        }
        
      }
      if(chSwtner){
       
        var optionset22 = chSweetOptions.find((sweet) => sweet.value === chSwtner);
       
        

      
        if(optionset22){
          var price22= optionset22.price.find((price)=> price.cake === "1000 grams")
          if(price22){
            
            addChSwtner=price22.priceTag
           
          }
        }
        
      }
      if(chocToppings){
            var optionsetarr1=chocToppings.map((topping) => toppingsOptions.find((choice)=> choice.value === topping))
        if(optionsetarr1){
          var pricearr1 = optionsetarr1.map((option) => (option.price.find((price)=> price.cake === "1000 grams")).priceTag)
          if(pricearr1){
            for (let index = 0; index < pricearr1.length; index++) {
              var element1 = pricearr1[index];
              addChToppings+=element1
            }
          }
        }
       
      }
      if(b===1){
        const chocolate = (jsonData.chocolate.s1000+(addChFrosty*1)+(addChToppings*1)+(addChSwtner*1)).toFixed(2)
        setChocolateTotal(chocolate)
        setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolate*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }else if(b>1){
        const chocolate = (((jsonData.chocolate.s1000am+(addChFrosty*1)+(addChToppings*1)+(addChSwtner*1))*b)+jsonData.chocolate.s1000ab).toFixed(2)
        setChocolateTotal(chocolate)
      setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolate*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }else{
        const chocolate=0
        setChocolateTotal(chocolate)
        setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolate*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }
    }else if(c==="650 grams"){
      if(chFrosty){

        var optionset=FrostyOptions.find((Frosty) => Frosty.value === chFrosty)
        if(optionset){
          var price = optionset.price.find((price)=> price.cake === "650 grams")
          if(price){
            addChFrosty=price.priceTag
          }
        }
        
      }
      if(chSwtner){

        var optionset3=chSweetOptions.find((Sweet) => Sweet.value === chSwtner)
        if(optionset3){
          var price3= optionset3.price.find((price)=> price.cake === "650 grams")
          if(price3){
            addChSwtner=price3.priceTag
          }
        }
        
      }
      if(chocToppings){
            var optionsetarr=chocToppings.map((topping) => toppingsOptions.find((choice)=> choice.value === topping))
        if(optionsetarr){
          var pricearr = optionsetarr.map((option) => (option.price.find((price)=> price.cake === "650 grams")).priceTag)
          if(pricearr){
            for (let index = 0; index < pricearr.length; index++) {
              var element = pricearr[index];
              addChToppings+=element
            }
          }
        }
       
      }
      if(b===1){
        const chocolate = (jsonData.chocolate.s650+(addChFrosty*1)+(addChToppings*1)+(addChSwtner*1)).toFixed(2)
        setChocolateTotal(chocolate)
        setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolate*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }else if(b>1){
        const chocolate = (((jsonData.chocolate.s650am+(addChFrosty*1)+(addChToppings*1)+(addChSwtner*1))*b)+jsonData.chocolate.s650ab).toFixed(2)
        setChocolateTotal(chocolate)
        setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolate*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }else{
        const chocolate=0
        setChocolateTotal(chocolate)
        setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolate*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }
    }else{
      const chocolate= 0
      setChocolateTotal(chocolate)
      setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolate*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
    }
  }


  return (

          <div>
            <div className='photoOrder'>
            <div className='cakesNameTotal'>
              <h3 className='cakeTitle'>Chocolate:</h3>
              <h3>${chocolateTotal}</h3>
            </div>
            <img className='photosOrder'src={image6} alt="chocolate cake"></img>

            </div>

            {isOrder===true?(
            <div>
              <h3>Chocolate size:{chocolateSize}</h3>
              <h3>Quantity:{chocolatequantity}</h3>
              <h3>Frosting:{chFrostyOption}</h3>
              <h3>Toppings:{chToppings}</h3>
            </div>
            ) : (
            <div>
              <div>         
                <label htmlFor="size-select-chocolate">
                  Select cake size:
                </label>
                <select id="size-select-chocolate" value={chocolateSize} onChange={handleChocolateChange}>
                  <option value="">Select size</option>
                  <option value="1000 grams">1000 grams</option>
                  <option value="650 grams">650 grams</option>
                </select>
              </div>
              <div className='btdiv'>
                <button className="btplusminus" style={{ cursor: 'pointer'}} onClick={handleChocolateDecrement}>-</button>
                <h4 className="qty">{chocolatequantity}</h4>
                <button className="btplusminus" style={{ cursor: 'pointer' }} onClick={handleChocolateIncrement}>+</button>
              </div>
            </div>)}
            {!isOrder && (
            <div>
              <button className='pmbutton'  onClick={handleChocToppVis}>
              <h3 style={{ textDecoration: 'underline' }}>Add Toppings</h3>
              </button>
              {toppingsOptions.map((option) => (
              <div key={option.value} className={chocHVtoppings} > 
                <input
                  type="checkbox"
                  value={option.value}
                  checked={chToppings.includes(option.value)}
                  onChange={handleChToppings}
                  disabled={
                  chToppings.length === 2 && !chToppings.includes(option.value)
                  }
                />
                <label>
                  <span>     {option.label}-$
          {
            chocolateSize &&
            option.price.find((price) => price.cake === chocolateSize)
              .priceTag
          }
      
                  </span>
                </label>
              </div>
              ))}
            </div>)}
            {!isOrder && (
            <div>
              <button className='pmbutton' onClick={handleChocFrosVis}>
              <h3 style={{ textDecoration: 'underline' }}>Add Frosting</h3>
                  </button>
              {FrostyOptions.map((option) => (
              <div key={option.value} className={chocHVfrosting}>
              <input
              type="checkbox"
              value={option.value}
              checked={chFrostyOption === option.value}
              onChange={handleChFrOpt}
              disabled={chFrostyOption !== '' && chFrostyOption !== option.value}
              />
              <label>
              <span>
              {option.label}-$
          {
             chocolateSize &&
             option.price.find((price) => price.cake === chocolateSize)
               .priceTag
          }
                </span>
              </label> 
              </div>
              ))}
            </div>
            )}
            {!isOrder && (
            <div>
              <h3>Select sweetener</h3>
              {chSweetOptions.map((option) => (
              <div key={option.value}>
              <input
              type="checkbox"
              value={option.value}
              checked={chSweetener === option.value}
              onChange={handleChSwOpt}
              
              />
              <label>
              <span>
              {option.label}-$
          {
             chocolateSize &&
             option.price.find((price) => price.cake === chocolateSize)
               .priceTag
          }
                </span>
              </label> 
              </div>
              ))}
            </div>
            )}
          </div>

    
  );
};

export default Chocolate;