import React from 'react';
import Canonical from '../components/Canonical'

const Error07 = ({
  setSubtotal,
  setTotalOrder,
  setorder,
  setSelectedDate,
  setSelectedTime,
  setmyemail,
  setmyname,
  setmyphone,
  setmyaddress,
  setIsFilled, 
}


) => {
  setorder('')
  setSelectedDate('')
  setIsFilled(false)
  setSubtotal('')
  setTotalOrder('')
  setSelectedTime('')
  setmyemail('')
  setmyname('')
  setmyphone('')
  setmyaddress('')

   
   const handleRedirectPayment = () =>{
   window.location.replace('/')

  }
      return(
      
         <div className='grid-container'>
                    <Canonical url="https://www.bitelandia.com/error07" />
           <button className='proceedBtn'
     style={{ cursor: 'pointer' }}
     onClick={handleRedirectPayment}
     >
     Go home
     </button>
     <h1>Error 07: Something went wrong, payment failed" </h1>
         </div>
       )
   
}

export default Error07;
