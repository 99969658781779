import React from 'react';

import jsonData from '../../data.json';

const Cookiealmonds = ({
  isOrder,
  cookieAlmQty,
  setCookieAlmQty,
  setCookieAlmTotal,
  cookieAlmTotal,
  chocolateTotal,
  carrotTotal,
  vanTotal,
  bananaTotal,
  pineTotal,
  cookieChocTotal,
  cookieMoTotal,
  tiramisuTotal,
  setTotal,

}) => 
{



function handleCookieAlmIncrement() {
  setCookieAlmQty(cookieAlmQty + 1);
  cookieAlmTotalcalc(cookieAlmQty + 1)
}

function handleCookieAlmDecrement() {
  if (cookieAlmQty=== 1) {
    setCookieAlmQty(0);
   cookieAlmTotalcalc(0)
  }else if(cookieAlmQty>1){
    setCookieAlmQty(cookieAlmQty-1);
    cookieAlmTotalcalc(cookieAlmQty-1)
  }
}

function cookieAlmTotalcalc(b) {

  if(b===1){
    const almCookie = (jsonData.cookiealm.set6).toFixed(2)
    setCookieAlmTotal(almCookie)
    setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(almCookie*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
  }else if(b>1){
    const almCookie= (((jsonData.cookiealm.set6am)*b)+jsonData.cookiealm.set6ab).toFixed(2)
    setCookieAlmTotal(almCookie)
    setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(almCookie*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
  }else{
    const almCookie =0
    setCookieAlmTotal(0)
    setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(almCookie*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
  }
}


return (
<div>
  <div className='photoOrder'>
    <div className='cakesNameTotal'>
      <h3 className='cakeTitle'>Almond:</h3>
      <h3>${cookieAlmTotal}</h3>
    </div>
      {/* <img className='photosOrder'src={image7} alt="banana cake"></img> */}

  </div>

    {isOrder===true?(
    <div>
      <h1>Quantity:{cookieAlmQty}</h1>

    </div>):
    (
    <div className='btdivflex'>
      <div  className='btdiv'>
      <button className="btplusminus" style={{ cursor: 'pointer'}}  onClick={handleCookieAlmDecrement}>-</button>
      <h4 className="qty">{cookieAlmQty}</h4>
      <button className="btplusminus" style={{ cursor: 'pointer'}}  onClick={handleCookieAlmIncrement}>+</button>
      </div>
      <div className='btdiv6'>
      <h4>x 6(150 grams)</h4>
      </div>
    </div>
    )}
</div>
);
};

export default Cookiealmonds;