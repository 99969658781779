import React from 'react';
import PayPalWrapper from './PayPalWrapper';
import Canonical from '../components/Canonical'

const Payment = ({

isFilled,

howdelivery,
objDate,
setObjDate,
order,
TotalOrder,
orderDescription,
selectedDate,
deliveryInstructions,
selectedTime,
myemail,
myaddress,
mypostalcode,
myname,
myphone,
setTotalOrder,
setSelectedDate,
setDeliveryInstructions,
setSelectedTime,
setmyemail,
setmyname,
setmyphone,
setmyaddress,
setpostalcode,
setIsFilled,
selectedCity,
setSelectedCity,
deliveryFee,
setDeliveryFee,
subTotal,
setSubtotal,
totalBeforeTaxes,
setTotalBeforeTaxes,
taxes,
setTaxes,
keyppl

}) => {
   
   const handleRedirectPayment = () =>{
  
      window.location.replace('/')

  }
   
  return (
   <>
    <Canonical url="https://www.bitelandia.com/ProcessPayment" />
     
     {isFilled === true ? (
       <PayPalWrapper
              isFilled={isFilled} 
              keyppl={keyppl}
              howdelivery={howdelivery}
              objDate = {objDate}
              setObjDate={setObjDate}
              order={order}
              TotalOrder={TotalOrder}
              orderDescription={orderDescription}
              selectedDate={selectedDate} 
              deliveryInstructions={deliveryInstructions} 
              selectedTime={selectedTime}
              myemail={myemail} 
              myaddress={myaddress}
              mypostalcode={mypostalcode}
              myname={myname}
              myphone={myphone}
              setTotalOrder={setTotalOrder}
              setSelectedDate={setSelectedDate} 
              setDeliveryInstructions={setDeliveryInstructions} 
              setSelectedTime={setSelectedTime}
              setmyemail={setmyemail}
              setmyname={setmyname}
              setmyphone={setmyphone}
              setmyaddress={setmyaddress}
              setpostalcode={setpostalcode}
              setIsFilled={setIsFilled}
              selectedCity={selectedCity}
              setSelectedCity={setSelectedCity}
              deliveryFee={deliveryFee}
              setDeliveryFee={setDeliveryFee}
              subTotal={subTotal}
              setSubtotal={setSubtotal}
              totalBeforeTaxes={totalBeforeTaxes}
              setTotalBeforeTaxes={setTotalBeforeTaxes}
              taxes={taxes}
              setTaxes={setTaxes}     />
              ) : (
                <div className='grid-container'>
                  <button className='proceedBtn'
                    style={{ cursor: 'pointer' }}
                    onClick={handleRedirectPayment}
                  >
                    Go home
                  </button>
                  <h1>nothing ordered yet</h1>
                </div>
              )}
            </>
          );
        }
        
        export default Payment;
