import { gql } from '@apollo/client';

export const ADD_ORDER= gql`
mutation AddOrder($name: String!, $address: String!, $total: String!, $deliveryDate: String!, $status: String!, $deliveryTime: String!, $postalCode: String!, $paypalRef: String!, $email: String, $phone: String, $delivery: String, $deliveryIns: String) {
  addOrder(name: $name, address: $address, total: $total, deliveryDate: $deliveryDate, status: $status, deliveryTime: $deliveryTime, postalCode: $postalCode, paypalRef: $paypalRef, email: $email, phone: $phone, delivery: $delivery, deliveryIns: $deliveryIns) {
    _id
    invoice
    delivery
    paypalRef
    payer
    name
    email
    phone
    address
    postalCode
    total
    deliveryDate
    deliveryTime
    status
    createdAt
    deliveryIns
  }
}
`;

export const PULL_HOUR = gql`
mutation PullHourDate($date: String!, $hour: String!) {
  pullHourDate(date: $date, hour: $hour) {
    _id
    date
    dateMill
    timeList {
      time
      isPicked
      timePicked
      timePickedMill
      timeMill
      timeInt
    }
  }
}
`;


export const UPDATE_ORDER= gql`
mutation UpdateOrder($orderId: ID!, $quantity: String!, $cake: String!, $total: String!, $frosty: String, $toppings: [String], $sweetener: String) {
  updateOrder(orderId: $orderId, quantity: $quantity, cake: $cake, total: $total, frosty: $frosty, toppings: $toppings, sweetener: $sweetener) {
    _id
    createdAt
  }
}
`;


export const UPDATE_STATUS= gql`
mutation UpdateOrderStatus($orderId: ID!, $newstatus: String!, $payerId: String!) {
  updateOrderStatus(orderId: $orderId, newstatus: $newstatus, payerId: $payerId) {
    _id
    invoice
    paypalRef
    payer
    name
    email
    phone
    address
    postalCode
    total
    deliveryDate
    deliveryTime
    status
    createdAt
    deliveryIns
  }
}
`;

export const UPDATE_TIME_TRUE= gql`
mutation UpdateTimeTrue($date: String!, $hour: String!) {
  updateTimeTrue(date: $date, hour: $hour) {
    _id
    date
    dateMill
    timeList {
      time
      isPicked
      timePicked
      timePickedMill
      timeMill
      timeInt
    }
  }
}
`;

export const UPDATE_TIME_FALSE= gql`
mutation UpdateTimeFalse($date: String!, $hour: String!) {
  updateTimeFalse(date: $date, hour: $hour) {
    _id
    date
    dateMill
    timeList {
      time
      isPicked
      timePicked
      timePickedMill
      timeMill
      timeInt
    }
  }
}
`;



