import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import ButtonWrapper from './ButtonWrapper';

function PayPalWrapper({
  howdelivery,
  objDate,
  order,
  TotalOrder,
  orderDescription,
  selectedDate,
  deliveryInstructions,
  selectedTime,
  myemail,
  myaddress,
  mypostalcode,
  myname,
  myphone,
  setIsFilled,
  selectedCity,
  deliveryFee, 
  subTotal,
  taxes,
  keyppl
}) {
 const currency = 'CAD'
  return (
    <PayPalScriptProvider
      options={{
        "client-id": keyppl,
        components: "buttons",
        currency: "CAD"
      }}

    >
      <ButtonWrapper  
                  currency={currency}
                  howdelivery={howdelivery}
                  objDate = {objDate}
                  order={order}
                  TotalOrder={TotalOrder}
                  orderDescription={orderDescription}
                  selectedDate={selectedDate} 
                  deliveryInstructions={deliveryInstructions} 
                  selectedTime={selectedTime}
                  myemail={myemail} 
                  myaddress={myaddress}
                  mypostalcode={mypostalcode}
                  myname={myname}
                  myphone={myphone}                        
                  setIsFilled={setIsFilled}
                  selectedCity={selectedCity}              
                  deliveryFee={deliveryFee}                  
                  subTotal={subTotal}                 
                  taxes={taxes}
                 />
      
    </PayPalScriptProvider>
  );
}

export default PayPalWrapper;
