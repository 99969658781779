import React from 'react';
import imageInst from '../components/img/instagram.png';

import imagewhatsap from '../components/img/whatsapp.png';


const Footer = () => {

  return (<div>
    <div className='fillerfooter'>
      -
    </div>
     <div className='footer'>
    <div className='followus'>
    <h4>Follow Us</h4>
      <a href="https://www.instagram.com/bItelandiacakes/" target="_blank" rel="noopener noreferrer"><img style={{width:"30px"}} src={imageInst} alt="logo Instagram"></img></a>
      {/* <a href="https://www.instagram.com/bItelandiacakes/" target="_blank" rel="noopener noreferrer"><img style={{width:"30px"}} src={imageface} alt="logo facebook"></img></a> */}
    </div>
    <div className='contactus'>
    <h4>Contact Us</h4>
      <h5>sales@bitelandia.com</h5>
      <div className='wsapctcus'>
      <img style={{width:"20px"}} src={imagewhatsap} alt="logo whatsap"></img> <span>+16478718533</span>
      </div>
      
      <h5>1130 Queens Ave, Oakville, L6H 2B6 </h5>
      
    </div>
  
    

 </div>
 <div className='fillerfooter'>
 <a href="https://www.javiermp.com/" target="_blank" rel="noopener noreferrer">Created by JMP</a>
    </div>

  </div>

  );
};

export default Footer;