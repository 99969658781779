import React from 'react';
import Canonical from '../components/Canonical'



const OrderUpdateErr = ({
  setSubtotal,
  setTotalOrder,
  setorder,
  setSelectedDate,
  setSelectedTime,
  setmyemail,
  setmyname,
  setmyphone,
  setmyaddress,
  setIsFilled, 
}

) => {
  setorder('')
  setSelectedDate('')
  setIsFilled(false)
  setSubtotal('')
  setTotalOrder('')
  setSelectedTime('')
  setmyemail('')
  setmyname('')
  setmyphone('')
  setmyaddress('')
  
   const handleRedirectPayment = () =>{
  
    window.location.replace('/')

  }
      return(
      
         <div className='grid-container'>
          <Canonical url="https://www.bitelandia.com/UpdateError" />
           <button className='proceedBtn'
     style={{ cursor: 'pointer' }}
     onClick={handleRedirectPayment}
     >
     Go home
     </button>
     <h1>Error: Something went wrong when updating your order,please check your paypal/debit/credit card and claim a refund if a money transfer was made.We apologize for any inconvenience</h1>
         </div>
       )
   
}

export default OrderUpdateErr;
