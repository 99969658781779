import React from 'react';

import jsonData from '../../data.json';

const Tiramisu = ({
  isOrder,
  tiramisuQty,
  setTiramisuQty,
  setTiramisuTotal,
  tiramisuTotal,
  cookieChocTotal,
  chocolateTotal,
  carrotTotal,
  vanTotal,
  bananaTotal,
  pineTotal,
  setTotal,
  cookieMoTotal,
  cookieAlmTotal

}) => 
{



function handleTiramisuIncrement() {
  
    setTiramisuQty(tiramisuQty + 1);
    tiramisuTotalcalc(tiramisuQty + 1)

}

function handleTiramisuDecrement() {
  if (tiramisuQty===1) {
    setTiramisuQty(0);
   tiramisuTotalcalc(0)
  }else if(tiramisuQty>1){
    setTiramisuQty(tiramisuQty-1);
    tiramisuTotalcalc(tiramisuQty-1)
  }
}

function tiramisuTotalcalc(b) {

  if(b===1){
    const tiramisud = (jsonData.tiramisu.cont).toFixed(2)
    setTiramisuTotal(tiramisud)
    setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(tiramisud*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)).toFixed(2))
  }else if(b>1){
    const tiramisud= (((jsonData.tiramisu.contam)*b)+jsonData.tiramisu.contab).toFixed(2)
    setTiramisuTotal(tiramisud)
    setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(tiramisud*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)).toFixed(2))
  }else{
    const tiramisud =0
    setTiramisuTotal(0)
    setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(tiramisud*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)).toFixed(2))
  }
}


return (
<div>
  <div className='photoOrder'>
    <div className='cakesNameTotal'>
      <h3 className='cakeTitle'>Tiramisu</h3>
      <h3>${tiramisuTotal}</h3>
    </div>
      {/* <img className='photosOrder'src={image7} alt="banana cake"></img> */}

  </div>

    {isOrder===true?(
    <div>
      <h1>Quantity:{tiramisuQty}</h1>

    </div>):
    (
    <div className='btdivflex'>
      <div  className='btdiv'>
      <button className="btplusminus" style={{ cursor: 'pointer'}}  onClick={handleTiramisuDecrement}>-</button>
      <h4 className="qty">{tiramisuQty}</h4>
      <button className="btplusminus" style={{ cursor: 'pointer'}}  onClick={handleTiramisuIncrement}>+</button>
      </div>
    </div>
    )}
</div>
);
};

export default Tiramisu;