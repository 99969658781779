import React from 'react';

// import { useQuery } from '@apollo/client';
// import {QUERY_TAMAL_DATES} from '../utils/queries'

const DeliveryOnly = ({
// cakeson,
// tamalon,
myemail,
myname,
myphone,
myaddress,
mypostalcode,
selectedDate,
selectedTime,
deliveryInstructions,
save,
handleAddress,
handleDeliveryInstructionsChange,
handleEmail,
handleName,
handlePhone,
handlePostalCode,
setSelectedCity,
selectedCity,
setSelectedDate,
setSelectedTime,
loading7,
error7,
data7,
alldates
}) => {

  // const { data:datatamales, loading:tamalesloading} = useQuery(QUERY_TAMAL_DATES);
  // const allTamalesdates= datatamales?.tamalDates || [];

  const dates= alldates.filter((date) => {
   
    if (!loading7&&!error7&&data7) {
      const thistime= data7.getCurrentTimeMill
      
      const Date = date.dateMill;
      
      const oneDay = 24 * 60 * 60 * 1000; // one day in milliseconds
    
      return (Date - thistime.currentTimeMill) >=oneDay;
    } else {
      return false
    }
  
  });
  // const ddates= allTamalesdates.filter((date) => {
   
  //   if (!loading7&&!error7&&data7) {
  //     const thistime= data7.getCurrentTimeMill
      
  //     const Date = date.dateMill;
      
  //     const oneDay = 24 * 60 * 60 * 1000; // one day in milliseconds
    
  //     return (Date - thistime.currentTimeMill) >=oneDay;
  //   } else {
  //     return false
  //   }
  
  // });
  const timeAvailable = (timeList) => {
    if(!loading7&&!error7&&data7){
     const thistime= data7.getCurrentTimeMill
     const treshold = (thistime.currentTimeMill*1) + (24 * 60 * 60 * 1000)
    
      const time = timeList.timeMill;
      
   if (time < treshold){
     return false;
   }
   
     if (timeList.isPicked===true){
       const thistime= data7.getCurrentTimeMill
     
   
       const timePicked = timeList.timePickedMill;
       return (timePicked*1) + 12 *60* 1000 < (thistime.currentTimeMill*1);
     }else{
         return true
     }
    }else{
     return false
    }
   
   };

  //  if(tamalesloading===true){
  //   return(<div className='homemin'>loading...</div>)
  //  }
   
    return (

      <div>
      
        <h3> Please note 
               that if you choose not to provide your phone number or email, 
               we may not be able to contact you in the event of any delivery issues.</h3>
        <div>    
       <form className='formOrder'>
        <div className='inputdiv'>
        <label className='labelorder' htmlFor="delname">*Invoice Recipient:</label>
        <input className='inputorder' type="text" id="delname" name="name" required value={myname}
        onChange={handleName}/>
        </div>
        <div className='inputdiv'>
        <label className='labelorder' htmlFor="delemail">Email:</label>
        <input className='inputorder' type="text" id="delemail" name="email" value={myemail}
        onChange={handleEmail}/>
        </div>
        <div className='inputdiv'>
        <label className='labelorder' htmlFor="delphone">Phone:</label>
        <input className='inputorder' type="text" id="delphone" name="phone" value={myphone}
        onChange={handlePhone}/>
        </div>
      
        <div>
        <div className='inputdiv' >
        <label className='labelorder' htmlFor="address">*Address for Delivery:</label>
        <input className='inputorder' type="text" id="deladdress" name="address" required value={myaddress}
        onChange={handleAddress} />
        </div>
      
        <div className='inputdiv'>
        <label className="citySelect" htmlFor="delcity">*City:</label>
        <select className="citytoselect" id="delcity" value={selectedCity} onChange={(e) => setSelectedCity(e.target.value)}>
        <option value="">Select a city</option>
        <option value="Mississauga">Mississauga</option>
        <option value="Burlington">Burlington</option>
        <option value="Oakville">Oakville</option>
        <option value="Milton">Milton</option>
        </select>
        </div>
        <div className='inputdiv'>
        <label htmlFor="delzip">*Postal Code:</label>
        <input className="inputZip" type="text" id="delzip" name="zip" required value={mypostalcode}
        onChange={handlePostalCode} />
        </div>
        </div> 
        <div className='inputdiv'>
        <label htmlFor="deldate">*Delivery Date:</label>
        <select className="deliveryD" id="deldate" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
        <option value="">Select a date</option>
        {dates.map((date) => {
      
        return (
        <option key={date._id} value={date.date}>
        {date.date}
        </option>
        );
        })}
        </select>
        </div> 
        <div className='inputdiv'>
        <label htmlFor="deltime">*Delivery Time:</label>
        <select className="deliveryT" id="deltime" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)}>
        <option value="">Select a time</option>
        {selectedDate &&
        !loading7 && 
        !error7 && 
        dates
        .find((date) => date.date === selectedDate)
        ?.timeList.filter(timeAvailable)
        .map((time) => {
        return (
        <option key={time.time} value={time.time}>
        {time.timeInt}
        </option>
        );
      })}
        </select>
        </div>
      
        <div className='inputdiv'>
        <label className='labelorder'  htmlFor="delivery-instructions">Delivery Instructions:</label>
        <textarea className='insOrder'
        id="delivery-instructions"
        name="delivery-instructions"
        value={deliveryInstructions}
        onChange={handleDeliveryInstructionsChange}
        rows="5"
        cols="65"
        />
        </div>
      
        </form>
        <div>
        <button  className='proceedBtn' style={{ cursor: 'pointer' }} onClick={save}>Proceed Payment</button>
        <span  className='textProceedBtn'>paypal, credit/debit card</span>
        </div>
        </div>
      </div>
      )
  
  //  if(cakeson===true&&tamalon===false){
  //   return (

  //     <div>
      
  //       <h3> Please note 
  //              that if you choose not to provide your phone number or email, 
  //              we may not be able to contact you in the event of any delivery issues.</h3>
  //       <div>    
  //      <form className='formOrder'>
  //       <div className='inputdiv'>
  //       <label className='labelorder' htmlFor="nameRe">*Invoice Recipient:</label>
  //       <input className='inputorder' type="text" id="nameRe" name="myemail" required value={myname}
  //       onChange={handleName}/>
  //       </div>
  //       <div className='inputdiv'>
  //       <label className='labelorder' htmlFor="email">email:</label>
  //       <input className='inputorder' type="text" id="email" name="myemail" value={myemail}
  //       onChange={handleEmail}/>
  //       </div>
  //       <div className='inputdiv'>
  //       <label className='labelorder' htmlFor="Phone">Phone:</label>
  //       <input className='inputorder' type="text" id="Phonne" name="myPhone" value={myphone}
  //       onChange={handlePhone}/>
  //       </div>
      
  //       <div>
  //       <div className='inputdiv' >
  //       <label className='labelorder' htmlFor="address">*Address for Delivery:</label>
  //       <input className='inputorder' type="text" id="address" name="myaddress" required value={myaddress}
  //       onChange={handleAddress} />
  //       </div>
      
  //       <div className='inputdiv'>
  //       <label className="citySelect" htmlFor="city">*City:</label>
  //       <select className="citytoselect" id="city" value={selectedCity} onChange={(e) => setSelectedCity(e.target.value)}>
  //       <option value="">Select a city</option>
  //       <option value="Mississauga">Mississauga</option>
  //       <option value="Burlington">Burlington</option>
  //       <option value="Oakville">Oakville</option>
  //       <option value="Milton">Milton</option>
  //       </select>
  //       </div>
  //       <div className='inputdiv'>
  //       <label htmlFor="zip">*Postal Code:</label>
  //       <input className="inputZip" type="text" id="zip" name="mypostalcode" required value={mypostalcode}
  //       onChange={handlePostalCode} />
  //       </div>
  //       </div> 
  //       <div className='inputdiv'>
  //       <label htmlFor="date">*Delivery Date:</label>
  //       <select className="deliveryD" id="date" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
  //       <option value="">Select a date</option>
  //       {dates.map((date) => {
      
  //       return (
  //       <option key={date._id} value={date.date}>
  //       {date.date}
  //       </option>
  //       );
  //       })}
  //       </select>
  //       </div> 
  //       <div className='inputdiv'>
  //       <label htmlFor="time">*Delivery Time:</label>
  //       <select className="deliveryT" id="time" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)}>
  //       <option value="">Select a time</option>
  //       {selectedDate &&
  //       !loading7 && 
  //       !error7 && 
  //       dates
  //       .find((date) => date.date === selectedDate)
  //       ?.timeList.filter(timeAvailable)
  //       .map((time) => {
  //       return (
  //       <option key={time.time} value={time.time}>
  //       {time.timeInt}
  //       </option>
  //       );
  //     })}
  //       </select>
  //       </div>
      
  //       <div className='inputdiv'>
  //       <label className='labelorder'  htmlFor="delivery-instructions">Delivery Instructions:</label>
  //       <textarea className='insOrder'
  //       id="delivery-instructions"
  //       name="delivery-instructions"
  //       value={deliveryInstructions}
  //       onChange={handleDeliveryInstructionsChange}
  //       rows="5"
  //       cols="65"
  //       />
  //       </div>
      
  //       </form>
  //       <div>
  //       <button  className='proceedBtn' style={{ cursor: 'pointer' }} onClick={save}>Proceed Payment</button>
  //       <span  className='textProceedBtn'>paypal, credit/debit card</span>
  //       </div>
  //       </div>
  //     </div>
  //     )
  //  }else{
  //   return (

  //     <div>
      
  //       <h3> Please note 
  //              that if you choose not to provide your phone number or email, 
  //              we may not be able to contact you in the event of any delivery issues.</h3>
  //       <div>    
  //      <form className='formOrder'>
  //       <div className='inputdiv'>
  //       <label className='labelorder' htmlFor="nameRe">*Invoice Recipient:</label>
  //       <input className='inputorder' type="text" id="nameRe" name="myemail" required value={myname}
  //       onChange={handleName}/>
  //       </div>
  //       <div className='inputdiv'>
  //       <label className='labelorder' htmlFor="email">email:</label>
  //       <input className='inputorder' type="text" id="email" name="myemail" value={myemail}
  //       onChange={handleEmail}/>
  //       </div>
  //       <div className='inputdiv'>
  //       <label className='labelorder' htmlFor="Phone">Phone:</label>
  //       <input className='inputorder' type="text" id="Phonne" name="myPhone" value={myphone}
  //       onChange={handlePhone}/>
  //       </div>
      
  //       <div>
  //       <div className='inputdiv' >
  //       <label className='labelorder' htmlFor="address">*Address for Delivery:</label>
  //       <input className='inputorder' type="text" id="address" name="myaddress" required value={myaddress}
  //       onChange={handleAddress} />
  //       </div>
      
  //       <div className='inputdiv'>
  //       <label className="citySelect" htmlFor="city">*City:</label>
  //       <select className="citytoselect" id="city" value={selectedCity} onChange={(e) => setSelectedCity(e.target.value)}>
  //       <option value="">Select a city</option>
  //       <option value="Mississauga">Mississauga</option>
  //       <option value="Burlington">Burlington</option>
  //       <option value="Oakville">Oakville</option>
  //       <option value="Milton">Milton</option>
  //       </select>
  //       </div>
  //       <div className='inputdiv'>
  //       <label htmlFor="zip">*Postal Code:</label>
  //       <input className="inputZip" type="text" id="zip" name="mypostalcode" required value={mypostalcode}
  //       onChange={handlePostalCode} />
  //       </div>
  //       </div> 
  //       <div className='inputdiv'>
  //       <label htmlFor="date">*Delivery Date:</label>
  //       <select className="deliveryD" id="date" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
  //       <option value="">Select a date</option>
  //       {ddates.map((date) => {
      
  //       return (
  //       <option key={date._id} value={date.date}>
  //       {date.date}
  //       </option>
  //       );
  //       })}
  //       </select>
  //       </div> 
  //       <div className='inputdiv'>
  //       <label htmlFor="time">*Delivery Time:</label>
  //       <select className="deliveryT" id="time" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)}>
  //       <option value="">Select a time</option>
  //       {selectedDate &&
  //       !loading7 && 
  //       !error7 && 
  //       ddates
  //       .find((date) => date.date === selectedDate)
  //       ?.timeList.filter(timeAvailable)
  //       .map((time) => {
  //       return (
  //       <option key={time.time} value={time.time}>
  //       {time.timeInt}
  //       </option>
  //       );
  //     })}
  //       </select>
  //       </div>
      
  //       <div className='inputdiv'>
  //       <label className='labelorder'  htmlFor="delivery-instructions">Delivery Instructions:</label>
  //       <textarea className='insOrder'
  //       id="delivery-instructions"
  //       name="delivery-instructions"
  //       value={deliveryInstructions}
  //       onChange={handleDeliveryInstructionsChange}
  //       rows="5"
  //       cols="65"
  //       />
  //       </div>
      
  //       </form>
  //       <div>
  //       <button  className='proceedBtn' style={{ cursor: 'pointer' }} onClick={save}>Proceed Payment</button>
  //       <span  className='textProceedBtn'>paypal, credit/debit card</span>
  //       </div>
  //       </div>
  //     </div>
  //     )
  //  }


}

export default DeliveryOnly;