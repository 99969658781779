

import React, { useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import image8 from '../../components/img/separatorlco.png'
import image9 from '../../components/img/separatorco.png'
import { COOKIES_PHOTOS} from '../../utils/queries';
import { useQuery } from '@apollo/client';


const Cookies = () => 
{

  const {  data: photocookies, loading: cookiesLoading} = useQuery(COOKIES_PHOTOS);
const cookiespics= photocookies?.Imagecookies || [];


const settings = {
  dots: true, // Show dots for navigation
  infinite: true, // Infinite loop
  speed: 1000, // Transition speed
  slidesToShow: 1, // Number of slides to show at a time
  slidesToScroll: 1, // Number of slides to scroll at a time
  swipeToSlide: true, // Allow swiping to navigate
  touchMove: true, // Enable touch gestures
  autoplay: true, // Auto-play the carousel
  autoplaySpeed: 7000,
};

const [showLessMore1, setShowLessMore1] = useState(true);
const [showLessMore2, setShowLessMore2] = useState(true);
const [showLessMore3, setShowLessMore3] = useState(true);


if(cookiesLoading){
  return <div>Loading...</div>
}

return (

<div>    

{cookiespics.length>0&&<div className='containercarrousel2'>
  <img
            className={'separator2a'}
          src={image9}
          alt="separator"
          />

      <Slider {...settings} className="mycarrousel">
      {cookiespics.map((image, index) => (
         <div key={index}className='carousel-slide'>
        <img  className='imgcarrousel2'src={image.image} alt={`COOKIE ${index}`} />
          <span className='textimagescarrousel'>{image.imagetext}</span>
          
        
         </div>
      ))}
    </Slider>
 
  </div>}
  {cookiespics.length>0&&<img
           className={cookiespics.length>1?'separator2':'separator'}
          src={image8}
          alt="separatorl"
          />}


  <div className='newingredientscookies'>
    <div>
      <div className='newingredients'>
        <h2 className='ingtitle'>
          Cookies Ingredients
        </h2>
      </div>

      <p className='ingredientsp' style={{ textAlign: 'justify', marginRight: "5px" }}>
        <span className='cakesIngr'>CHOCOLATE cookie</span> : {showLessMore1 ? `oat flour, almond flour`:
        `oat flour, almond flour, baking powder, maple syrup, coconut oil, eggs, cocoa, dark chocolate chips
        ⚠️traces of nuts and milk⚠️`}
        <span><button className='ingvisible'onClick={() => setShowLessMore1(!showLessMore1)}>
        {showLessMore1 ? '...See More' :'See Less' }
      </button></span> 
      </p>

      <p className='ingredientsp' style={{ textAlign: 'justify', marginRight:"5px" }}>
        <span className='cakesIngr'>ALMOND cookie</span> :{showLessMore2 ?`oat flour, almond flour`:
        `oat flour, almond flour, coconut sugar, eggs,
       almonds,⚠️traces of nuts and milk⚠️`}
        <span><button className='ingvisible'onClick={() => setShowLessMore2(!showLessMore2)}>
        {showLessMore2 ? '...See More' :'See Less' }
      </button></span> 
      </p>

      <p className='ingredientsp' style={{ textAlign: 'justify', marginRight:"5px" }}>
        <span className='cakesIngr' >MOCHA cookie(vegan)</span> :{showLessMore3 ?`almond flour, baking powder`: 
     
     `almond flour, baking powder, cocoa, coffee,
        ⚠️traces of nuts and milk⚠️`}
        <span><button className='ingvisible'onClick={() => setShowLessMore3(!showLessMore3)}>
        {showLessMore3 ? '...See More' :'See Less' }
      </button></span> 
      </p>
     

    
    </div>

  </div>
</div>
)
}

export default Cookies;




