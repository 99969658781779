import React from 'react';
import { Helmet } from 'react-helmet';

const Canonical = ({ url }) => (
  <Helmet>
    <link rel="canonical" href={url} />
  </Helmet>
);

export default Canonical;
