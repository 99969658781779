import React from 'react';

import { useQuery } from '@apollo/client';
// import {QUERY_TAMAL_PDATES} from '../utils/queries'


import { QUERY_PICKUP_DATES} from '../utils/queries';

const PickupsOnly = ({
  // cakeson,
  // tamalon,
myname,
myemail,
myphone,
selectedDate,
setSelectedDate,
setSelectedTime,
selectedTime,
save,
loading7,
data7,
handleEmail,
handleName,
handlePhone,
error7


}) => {
  

  const { data: data4, loading:loadingpickdates } = useQuery(QUERY_PICKUP_DATES);
const allpdates= data4?.PickDates || [];

 
  const pdates= allpdates.filter((date) => {

    if (!loading7&&!error7&&data7) {
      const thistime= data7.getCurrentTimeMill
      const selectedDate = date.dateMill;
      const oneDay = 24 * 60 * 60 * 1000; // one day in milliseconds
    
      return (selectedDate - thistime.currentTimeMill) >=oneDay;
    } else {
      return false
    }
  
  });

  const handleselecteddate = (k) => {
    const selecteddate= k
    const mytime =allpdates.find((date) => date.date === k)
    if(mytime===null||mytime===undefined){
      setSelectedDate('')
  
      setSelectedTime('')
    }else{
      setSelectedDate(selecteddate)
  
      setSelectedTime(mytime.formathour)
    }
  
    }

    if(loadingpickdates===true){
      return(<div className='homemin'>Loading..</div>)
    }
      return(

        <div>
          <div>     
            <form className='formOrder'>
              <div className='inputdiv'>
                <label className='labelorder' htmlFor="nameRe">*Invoice Recipient:</label>
                <input className='inputorder' type="text" id="nameRe" name="name" required value={myname}
                onChange={handleName}/>
              </div>
              <div className='inputdiv'>
                <label className='labelorder' htmlFor="email">email:</label>
                <input className='inputorder' type="text" id="email" name="email" value={myemail}
                onChange={handleEmail}/>
              </div>
              <div className='inputdiv'>
                <label className='labelorder' htmlFor="Phone">Phone:</label>
                <input className='inputorder' type="text" id="Phonne" name="phone" value={myphone}
                onChange={handlePhone}/>
              </div>
              <div className='inputdiv'>
                <label htmlFor="date">*Pick up Date:</label>
                <select className="deliveryD" id="date" value={selectedDate} onChange={(e) => handleselecteddate(e.target.value)}>
                <option value="">Select a date</option>
                {pdates.map((date) => {
                return (
                <option key={date._id} value={date.date}>
                {date.date}
                </option>
                );
                })}
                </select>
              </div>
              <div>
              {selectedDate&&<h3>Your order will be available to pick up between {selectedTime}
                
                </h3>}
               
                <h3>
              Pick up location: 1130 Queens Ave, Oakville, L6H 2B6. Please, send us a text or watsApp message providing your invoice# to our contact number once you arrive to the premises. Thank you
              </h3></div>
            </form>
            <div>
            <button  className='proceedBtn' style={{ cursor: 'pointer' }} onClick={save}>Proceed Payment</button>
            <span  className='textProceedBtn'>paypal, credit/debit card</span>
            </div>
          </div>
        
        
        
        </div>
        )
 
}

export default PickupsOnly;