import React from 'react';

const NotFound = () => {
  const centerStyle = {
    display: 'flex', 
    justifyContent: 'center'
  };

  return (
    <div className='home' style={{minHeight: "70vh"}}>
        <div style={centerStyle}>
            <h1>
                Oops
            </h1>
        </div>
        <div style={centerStyle}>
            <h1>
                PAGE NOT FOUND
            </h1>
        </div>
        <div style={centerStyle}>
            <a href="https://www.bitelandia.com/">GO BACK TO HOME</a>
        </div>
    </div>
  );
};

export default NotFound;
