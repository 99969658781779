import React from 'react';

import jsonData from '../../data.json';

const cookieChoc = ({
  isOrder,
  cookieChocQty,
  setCookieChocQty,
  setCookieChocTotal,
  cookieChocTotal,
  chocolateTotal,
  carrotTotal,
  vanTotal,
  bananaTotal,
  pineTotal,
  setTotal,
  cookieMoTotal,
  tiramisuTotal,
  cookieAlmTotal


}) => 
{



function handlecookieChocIncrement() {
  setCookieChocQty(cookieChocQty + 1);
  cookieChocTotalcalc(cookieChocQty + 1)
}

function handlecookieChocDecrement() {
  if (cookieChocQty=== 1) {
    setCookieChocQty(0);
   cookieChocTotalcalc(0)
  }else if(cookieChocQty>1){
    setCookieChocQty(cookieChocQty-1);
    cookieChocTotalcalc(cookieChocQty-1)
  }
}

function cookieChocTotalcalc(b) {

  if(b===1){
    const chCookie = (jsonData.cookiechoco.set6).toFixed(2)
    setCookieChocTotal(chCookie)
    setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(chCookie*1)+(cookieAlmTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
  }else if(b>1){
    const chCookie= (((jsonData.cookiechoco.set6am)*b)+jsonData.cookiechoco.set6ab).toFixed(2)
    setCookieChocTotal(chCookie)
    setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(chCookie*1)+(cookieAlmTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
  }else{
    const chCookie =0
    setCookieChocTotal(0)
    setTotal(((carrotTotal*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(chCookie*1)+(cookieAlmTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
  }
}


return (
<div>
  <div className='photoOrder'>
    <div className='cakesNameTotal'>
      <h3 className='cakeTitle'>Chocolate:</h3>
      <h3>${cookieChocTotal}</h3>
    </div>
      {/* <img className='photosOrder'src={image7} alt="banana cake"></img> */}

  </div>

    {isOrder===true?(
    <div>
      <h1>Quantity:{cookieChocQty}</h1>

    </div>):
    (
    <div className='btdivflex'>
      <div  className='btdiv'>
      <button className="btplusminus" style={{ cursor: 'pointer'}}  onClick={handlecookieChocDecrement}>-</button>
      <h4 className="qty">{cookieChocQty}</h4>
      <button className="btplusminus" style={{ cursor: 'pointer'}}  onClick={handlecookieChocIncrement}>+</button>
      </div>
      <div className='btdiv6'>
      <h4>x 6(150 grams)</h4>
      </div>
    </div>
    )}
</div>
);
};

export default cookieChoc;