export const chSweetOptions = [
    { label: 'Coconut Sugar', value: 'Coconut Sugar', price:[{cake:"1000 grams", priceTag:1.17},{cake:"650 grams", priceTag:0.76}]},
    { label: 'Monk Fruit', value: 'Monk Fruit', price:[{cake:"1000 grams", priceTag:1.73},{cake:"650 grams", priceTag:1.13}] },

  ];
export const banSweetOptions = [
    { label: 'Coconut Sugar', value: 'Coconut Sugar', price:[{cake:"1000 grams", priceTag:1.13},{cake:"650 grams", priceTag:0.75}]},
    { label: 'Monk Fruit', value: 'Monk Fruit', price:[{cake:"1000 grams", priceTag:1.68},{cake:"650 grams", priceTag:1.10}] },

  ];
export const carrSweetOptions = [
    { label: 'Coconut Sugar', value: 'Coconut Sugar', price:[{cake:"1000 grams", priceTag:1.10},{cake:"650 grams", priceTag:0.71}]},
    { label: 'Monk Fruit', value: 'Monk Fruit', price:[{cake:"1000 grams", priceTag:1.20},{cake:"650 grams", priceTag:0.77}] },

  ];
export const pineSweetOptions = [
    { label: 'Coconut Sugar', value: 'Coconut Sugar', price:[{cake:"1000 grams", priceTag:1.10},{cake:"650 grams", priceTag:0.71}]},
    { label: 'Monk Fruit', value: 'Monk Fruit', price:[{cake:"1000 grams", priceTag:1.20},{cake:"650 grams", priceTag:0.77}] },

  ];
export const vanSweetOptions = [
    { label: 'Coconut Sugar', value: 'Coconut Sugar', price:[{cake:"1000 grams", priceTag:1.10},{cake:"650 grams", priceTag:0.71}]},
    { label: 'Monk Fruit', value: 'Monk Fruit', price:[{cake:"1000 grams", priceTag:1.20},{cake:"650 grams", priceTag:0.77}] },

  ];

 