
import image5 from '../../components/img/separator.png'
import image6 from '../../components/img/separatorl.png'
import React from 'react';
import { useState, useEffect} from 'react';
import { QUERY_PHOTOS} from '../../utils/queries';
import { useQuery } from '@apollo/client';

const Cakes = () => 
{
const {  data: data8} = useQuery(QUERY_PHOTOS);
const PhotosBite= data8?.getPhotoCollection || [];
const [currentImageIndex, setCurrentImageIndex] = useState(0);

useEffect(() => {

  if (!PhotosBite.photos) {
    return;
  }

  const interval = setInterval(() => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === PhotosBite.photos.length - 1 ? 0 : prevIndex + 1
    );
  }, 5000);

  return () => {
    clearInterval(interval);
  };
}, [PhotosBite.photos]);

const [showLessMore1, setShowLessMore1] = useState(true);
const [showLessMore2, setShowLessMore2] = useState(true);
const [showLessMore3, setShowLessMore3] = useState(true);
const [showLessMore4, setShowLessMore4] = useState(true);
const [showLessMore5, setShowLessMore5] = useState(true);

return (


<div>


<div className='containercarrousel'>
<img
  className='separator'
  src={image5}
  alt="separator"
  
  />
{PhotosBite.photos &&
PhotosBite.photos.map((photo, index) => (
  <div 
    key={photo.photourl}
   
    style={{
      
      display: index === currentImageIndex ? " " : "none",
      padding:"0px",
      margin:"0px"
  
    }}
  >
    <div className='imagescarrouselcontainter'>
    <img
    className='imagescarrousel'
      src={photo.photourl}
      alt={`cake Description:${photo.phototext}`}
    />
    <span className='textimagescarrousel'>{photo.phototext}</span>

  </div>

  </div>
))}
<img
className='separator'
src={image6}
alt="separatorl"
/>
</div>
<div className='newingredients'>
    <div>
      <div className='newingredients'>
        <h2 className='ingtitle'>
          Cakes Ingredients
        </h2>
      </div>

      <p className='ingredientsp' style={{ textAlign: 'justify', marginRight: "5px" }}>
        <span className='cakesIngr'>🎂 CHOCOLATE cake</span> : {showLessMore1? `organic cocoa, almond flour`:  
     
     `organic cocoa, almond flour, baking powder, coconut oil, coconut sugar/monk fruit, eggs, rice flour, vanilla, veggie milk,
        ⚠️traces of nuts and milk⚠️.`}
        <span><button className='ingvisible'onClick={() => setShowLessMore1(!showLessMore1)}>
        {showLessMore1 ? '...See More' :'See Less' }
      </button></span> 
      
     
      </p>

      <p className='ingredientsp' style={{ textAlign: 'justify', marginRight:"5px" }}>
        <span className='cakesIngr'> 🥕 CARROT cake</span> :{showLessMore2? `shreded carrots, almond flour`: 
      
      `shreded carrots, walnuts, almond flour, baking powder, coconut oil,	coconut sugar/monk fruit, eggs,
        ginger, grinded cinamon, nutmeg, rice flour,⚠️traces of nuts and milk⚠️.`}
        <span><button className='ingvisible'onClick={() => setShowLessMore2(!showLessMore2)}>
        {showLessMore2 ? '...See More' :'See Less' }
      </button></span> 
      
      </p>

      <p className='ingredientsp' style={{ textAlign: 'justify', marginRight:"5px" }}>
        <span className='cakesIngr' >🍌 BANANA cake</span> :{showLessMore3? `bananas, coconut oil`: 
     
     `bananas, coconut oil, almond flour, baking powder, coconut sugar, eggs,
        rice flour,⚠️traces of nuts and milk⚠️.`}
        <span><button className='ingvisible'onClick={() => setShowLessMore3(!showLessMore3)}>
        {showLessMore3 ? '...See More' :'See Less' }
      </button></span> 
     
      </p>

      <p className='ingredientsp' style={{ textAlign: 'justify', marginRight:"5px" }}>
        <span className='cakesIngr' >🍍 PINEAPPLE cake</span> : {showLessMore4? `pineapple, oat flour`: 
      
      `pineapple, oat flour, almond flour, baking powder, coconut oil, coconut sugar/monk fruit, eggs, 
        ⚠️traces of nuts and milk⚠️.`}
        <span><button className='ingvisible'onClick={() => setShowLessMore4(!showLessMore4)}>
        {showLessMore4 ? '...See More' :'See Less' }
      </button></span> 
      </p>

      <p className='ingredientsp' style={{ textAlign: 'justify', marginRight:"5px" }}>
        <span className='cakesIngr' >🍦 VANILLA cake</span> :{showLessMore5? `natural vanilla,  rice flour`:
          `natural vanilla,  rice flour, almond flour, baking powder, coconut oil, coconut sugar/monk fruit, eggs, salt, lemon, veggie milk,
        ⚠️traces of nuts and milk⚠️.`}
        <span><button className='ingvisible'onClick={() => setShowLessMore5(!showLessMore5)}>
        {showLessMore5 ? '...See More' :'See Less' }
      </button></span> 
      </p>
    </div>

  </div>
</div>
 

)
}

export default Cakes;

